import React, { FunctionComponent, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Input from '../../../components/Elements/Input/Input';
import { Input as Checkbox, FormFeedback } from 'reactstrap';

import { SpinnerLoader } from 'components/Common/Loader/Loader';
import Modal from 'components/Common/Modal/Modal';
import { Alert } from 'utils/Alerts';

import RegexLibrary from '../../../utils/Regex';
import HttpAuth from '../../../services/http/Auth';

import StorageAuth from '../../../services/storage/Auth';

import Notification from '../../../components/Elements/Notification';
import ErrorException from '../../../services/exception/ErrorException';
import PermissionProvider from 'providers/permissionProvider';
import { useHistory } from 'react-router-dom';
import PATHS, { ROUTES } from 'permissions/roles/AppPaths';
import RightsReserved from 'UI/molecules/RightsReserved';
import HandlePasswordVisibility, { isEmptyValue, VisibilityPassword } from 'UI/atoms/PasswordVisibility';
import "../../Template/Modals/ModalBase/ModalBase.scss"
import FloatingLabelInput from 'components/Elements/Input/FloatingLabel';

import routes from "permissions/roles/AppPaths";

const reducer = (state: any, action: any) => {
	switch (action.type) {
		case 'email':
			return { ...state, email: action.value };
		case 'password':
			return { ...state, password: action.value };
		default:
			return state;
	}
}

const emptyInitialState = {
	email: false,
	password: false
}

const LoginForm: FunctionComponent = (props: any) => {
	const { t }: any = useTranslation();
	const history = useHistory();

	const [emailValue, setEmailValue] = useState('');
	const [emailIsValid, setEmailIsValid] = useState(true);
	const [emailFeedbackMessage, setEmailFeedbackMessage] = useState('');
	const [resetEmailValue, setResetEmailValue] = useState('');
	const [resetEmailIsValid, setResetEmailIsValid] = useState(true);
	const [resetEmailFeedbackMessage, setResetEmailFeedbackMessage] = useState('');
	const [passwordValue, setPasswordValue] = useState('');
	const [passwordIsValid, setPasswordIsValid] = useState(true);
	const [passwordFeedbackMessage, setPasswordFeedbackMessage] = useState('');
	const [rememberMeValue, setRememberMeValue] = useState(false);
	const [loading, setLoading] = useState(false);
	const [loadingModal, setLoadingModal] = useState(false);
	const [showModal, setShowModal] = useState('');

	const [error, setError] = useState(false);
	const [emptyState, dispatch] = React.useReducer(reducer, emptyInitialState);

	const [inputTypePassword, setInputTypePassword] = useState<VisibilityPassword>('password')

	const [permissionAccess, setPermissionAccess]: any = useContext(PermissionProvider);
	const h = history || props.history;



	const validateResetEmail = () => {
		let isValid = true;
		let feedbackMessage: string | any = '';

		if (!resetEmailValue) {
			isValid = false;
			feedbackMessage = t('common-notEmpty');
		} else if (!RegexLibrary.email.test(resetEmailValue)) {
			isValid = false;
			feedbackMessage = t('common-emailFormat');
		}

		setResetEmailIsValid(isValid);
		setResetEmailFeedbackMessage(feedbackMessage);

		return isValid;
	};

	const validateEmail = () => {
		let isValid = true;
		let feedbackMessage: string | any = '';

		if (!emailValue) {
			isValid = false;
			feedbackMessage = t('common-notEmpty');
		} else if (!RegexLibrary.email.test(emailValue)) {
			isValid = false;
			feedbackMessage = t('common-emailFormat');
		}

		setEmailIsValid(isValid);
		setEmailFeedbackMessage(feedbackMessage);

		return isValid;
	};

	const validatePassword = () => {
		let isValid = true;
		let feedbackMessage: string | any = '';

		if (!passwordValue) {
			isValid = false;
			feedbackMessage = t('common-notEmpty');
		}
		setPasswordIsValid(isValid);
		setPasswordFeedbackMessage(feedbackMessage);

		return isValid;
	};

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		if (!emailValue) {
			dispatch({ type: 'email', value: true });
		} else {
			dispatch({ type: 'email', value: false });
		}
		if (!passwordValue) {
			dispatch({ type: 'password', value: true });
		} else {
			dispatch({ type: 'password', value: false });
		}

		const bothElementEmpty = !validateEmail() && !validatePassword();

		if (bothElementEmpty) return;

		if (!validateEmail() || !validatePassword()) return;

		setLoading(true);

		try {
			const response = await new HttpAuth().login(emailValue, passwordValue, rememberMeValue);
			if (response) {
				setLoading(false);
				const storage = new StorageAuth();
				storage.saveSession(
					{
						accessToken: response.accessToken,
						refreshToken: response.refreshToken,
						rememberMe: rememberMeValue,
					}
				);
				const user = storage.decodeAccessToken(response.accessToken);
				setPermissionAccess(user.roles);

				//PENDO TRACKING
				if (!emailValue.includes('@bcome')) {
					window.pendo.initialize({
						visitor: {
							id: user.userId,
							email: emailValue
						},

						account: {
							id: user.accountId,
							name: user.accountName,
							email: emailValue
						}
					});
				}

				setError(false);

				h.push(PATHS.home);
			}
		} catch (err) {
			console.log("ERR", err);
			setLoading(false);
			setError(true);
			return;
		}
	};

	const handleModal = async () => {
		if (!validateResetEmail()) return;

		setLoadingModal(true);
		try {
			const resetPassword = await new HttpAuth().resetPassword(resetEmailValue);
			if (resetPassword) {
				Alert(
					t('alert-congratulations'),
					t('alert-success-resetPassword'),
					'success',
					false
				);
			}
		} catch (err) {
			setResetEmailFeedbackMessage(err);
		}
		setLoadingModal(false);
		setShowModal('');
	};

	const handleChangeEmail = (value: string) => {
		setEmailValue(value);
	}

	const handleChangePassword = (value: string) => {
		setPasswordValue(value);
	}

	return (
		<>
			<div className='fixed-top w-100 d-flex justify-content-end p-3 font-sfpro-regular text-dark-100 font-14px'>
				<nav>
					<ul className='d-flex' style={{ listStyle: 'none', gap: 10 }}>
						<li>
							<a href="https://bcome.biz/es/help-center/" target='__blank'>Help</a>
						</li>
						<li>
							<a href="mailto:info@bcome.biz">Contact</a>
						</li>
						<li>
							<a href={routes.login}>Login</a>
						</li>
					</ul>
				</nav>
			</div>
			<div id="login-form">
				<div id="form-container">
					<div className="col-12">
						<div className="d-flex flex-column justify-content-center align-items-center">
							<img style={{ width: '160px', marginBottom: 80 }} src="../img/bcome-logo1.svg" alt="logo" />
							<div className='w-100' style={{ marginBottom: 40 }}>
								<h1 className='mb-3 font-14px'>Log in</h1>
								<h2 className='font-14px'>Welcome to the impact-driven platform for fashion.</h2>
							</div>
						</div>
					</div>
					<div className="col-12">
						<form onSubmit={handleSubmit}>
							{
								error && (
									<div>
										<h3 className='font-14px'>Wrong email address or password.</h3>
									</div>
								)
							}
							<div className="w-100">
								<FloatingLabelInput
									label='Email'
									type='username'
									value={emailValue}
									setValue={handleChangeEmail}
									required={true}
									invalid={!emailIsValid || emptyState.email}
									feedbackMessage={emailFeedbackMessage}
									validateValue={validateEmail}
								/>
							</div>
							<div className="w-100 d-flex flex-column align-items-start">
								<FloatingLabelInput
									label='Password'
									type={inputTypePassword}
									value={passwordValue}
									setValue={handleChangePassword}
									required={true}
									invalid={!passwordIsValid || emptyState.password}
									setInputTypePassword={setInputTypePassword}
									feedbackMessage={passwordFeedbackMessage}
									validateValue={validatePassword}
								/>
								{/* <HandlePasswordVisibility inputType={inputTypePassword} setInputType={setInputTypePassword} isEmpty={isEmptyValue(passwordValue)} /> */}
							</div>
							<div className="col-12 forgot-password px-0">
								<div className='text-dark-100' onClick={() => setShowModal('modal-forgot-password')}>
									{t('login-forgotPassword')}
								</div>
							</div>
							<div className="col-12 remember-me-container px-0">
								<div className="checkbox c-checkbox font-sfpro-regular">
									<label className='m-0 d-flex align-items-center text-dark-100'>
										<Checkbox
											checked={rememberMeValue}
											onChange={(e: any) =>
												setRememberMeValue(e.target.checked)
											}
											type="checkbox"
										/>
										<span className="fa fa-check text-dark-100"
											style={{ width: '17px', height: '17px' }}></span>&nbsp;
										{t('login-remember')}
									</label>
								</div>
							</div>
							<div className="col-12 px-0" style={{ paddingTop: 20 }}>
								<button
									type="submit"
									style={{ height: '42px' }}
									className="btn btn-primary d-flex justify-content-center w-100 align-items-center"
									disabled={loading}>
									<span className='font-sfpro-medium'>{t('login-toBcome')}</span>
									{loading && <SpinnerLoader />}
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
			<RightsReserved />

			<Modal
				modal={showModal}
				setModal={setShowModal}
				className="page-selector-modal"
				scrollable
				createText={t('common-send')}
				handleSubmit={handleModal}
				disableButton={false}
				title={t('login-resetPassword')}
				size="md"
				isLoading={loadingModal}
				style={{ height: '180px', minHeight: '0px' }}
				modalName="modal-forgot-password">
				<div className="modal-container align-content-start" >
					<div className="col-12">
						<div className='font-14px text-black-100 font-sfpro-regular mt-3 mb-4'>{t('login-resetPassword-info')}</div>
						<Input
							type="text"
							placeholder="yourname@example.com"
							autoFocus={true}
							invalid={!resetEmailIsValid}
							value={resetEmailValue}
							className="login-input"
							onChange={(e: any) => setResetEmailValue(e.target.value)}
							labelText={t('common-email')}
							labelClassName="login-input-title"
							icon="iconClassName atSign"
						/>
						{resetEmailFeedbackMessage && (
							<FormFeedback>{resetEmailFeedbackMessage}</FormFeedback>
						)}
					</div>
				</div>
			</Modal>
		</>
	);
};

export default LoginForm;
