import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import MetricUnit from 'UI/atoms/MetricUnit';
import SortArrows from 'UI/atoms/SortArrows';
import FunctionalUnitTooltips from 'UI/atoms/Tooltips/FunctionalUnitTooltips';
import { useTable } from 'contexts/tableContext';
import {
	IndicatorsGroups,
	StagesGroups,
} from 'UI/Template/Table/types/DataGroups';
import ColorIcon from 'UI/atoms/ColorIcon';
import { SimpleTooltip } from 'UI/atoms/Tooltips/Index';
import { isDivOverflow } from 'utils/checkIfTextOverflow';
import { validateReferenceType } from 'utils/utils';
import { useProjects } from 'contexts/projectsContext';

interface Props {
	textSubTitle?: string;
	indicator?: string;
	stage?: string;
	metricUnit?: string;
	dataType?: string;
	columnId?: string;
	metricName: string;
}

export enum ESortTypes {
	ASC = 'ASC',
	DESC = 'DESC',
	NONE = 'NONE',
}

const metricsWithoutSortBy = [
	'category',
	'amount',
	'amountArticle',
	'totalArticleMaterial',
	'Traceability_Suppliers',
	'Traceability_Certificates',
	'Supply_Chain_Knowledge',
	'scans',
	'smartlabelUrl',
	'qrCodeUrl',
	'tiers',
	'origin',
	'categoryId',
	'transport',
	'material_certificate_names',
	'supplier_tiers',
	'supplier_origin_abbreviation',
	'supplier_transport_category',
	'supplier_certificate_names',
	'supplier_closedLoopWaterSystem_validation',
	'supplier_renewableEnergy_validation',
	'material_category_names',
	'material_process_extraction_relativeAmounts',
	'material_process_finishing_supplier_names',
	'material_process_matProcessing_supplier_name',
	'material_process_rmatProcessing_supplier_names',
	'material_process_extraction_supplier_names',
	'name',
	'contArticle',
	'referenceType',
	'dataCollectionURL',
	'status',
	'createdAt',
	'closingDate',
	'folderURL',
	'saved'
];

const metricsWithoutTooltip = [
	'articleInfo',
	'supplierName',
	'materialName',
	'scans',
	'smartlabelUrl',
	'qrCodeUrl',
	'productCategory',
	'categoryTraceability',
	'stage',
	'sidebar-nav-people',
	'material_category_names',
	'warehouseOrigin',
	'manufacturingTransport',
	'manufacturingOrigin',
	'primaryRetail',
	'secondaryWarehouse',
	'terciaryShipping',
	'supplier_renewableEnergy_validation',
	'project_name',
	'total_ref',
	'reference_type',
	'form_url',
	'status',
	'creation_date',
	'closing_date',
	'folder_url',
	'saved-list'
];

const growColumnTextByName = ['completionTraceability', 'total_waste', 'mci_score', 'form_url']
const growColumn = ['form_url', 'status', 'creation_date', 'closing_date']

function SubheaderColumnArrows(props: Props) {
	const { projectSelected } = useProjects();
	const {
		textSubTitle,
		indicator = '',
		stage = '',
		metricUnit,
		dataType,
		columnId,
		metricName,
	} = props;
	const {
		sortMetric,
		setSortMetric,
		setsortColumnId,
		sortColumnId,
		dataGroupSelected,
		openColumnSelectorModal,
	} = useTable();
	const { t } = useTranslation();
	const [isTextOverflowed, setIsTextOverflowed] = useState(false); // show unit metric tooltip only if text is overflowed
	const [functionalTooltipOpen, setFunctionalTooltipOpen] = useState(false);
	const [colorIconTooltipOpen, setColorIconTooltipOpen] = useState(false);
	const [metricUnitTooltipOpen, setMetricUnitTooltipOpen] = useState(false);
	const [subTitleTooltipOpen, setSubTitleTooltipOpen] = useState(false);
	const dataTypeColumnId = `${columnId}-dataType`;
	const colorIconColumnId = `${columnId}-colorIcon`;
	const metricUnitColumnId = `${columnId}-metricUnit`;
	const textColumnId = `${textSubTitle}-${metricName}`;

	const wPath = window.location.pathname;
	const product = wPath.split('/')[2];

	const ref = useRef<any>(null);
	const h = useHistory();

	const initialSort = () => {
		if (sortColumnId === columnId) return sortMetric[0].order;
		else return ESortTypes.NONE;
	};

	const [sortType, setSortType] = useState(initialSort());

	const compareMetricTextSize = () => {
		const isOverflow = isDivOverflow(ref);
		setIsTextOverflowed(isOverflow);
	};

	// compare once and add resize listener on "componentDidMount"
	useEffect(() => {
		compareMetricTextSize();
		window.addEventListener('resize', compareMetricTextSize);

		return () => {
			window.removeEventListener('resize', compareMetricTextSize);
		}
	}, [openColumnSelectorModal]);

	const requestDataForNextSorting = () => {
		if (sortType === 'NONE' || columnId !== sortColumnId) {
			setSortMetric([{ field: metricName, order: ESortTypes.DESC }]);
			setsortColumnId(columnId);
		} else if (sortType === 'DESC') {
			setSortMetric([{ field: metricName, order: ESortTypes.ASC }]);
			setsortColumnId(columnId);
		} else if (sortType === 'ASC') {
			setSortMetric([]);
			setsortColumnId('');
		}
	};

	const rotateSorting = () => {
		setSortType((state: any) => {
			if (columnId !== sortColumnId) return ESortTypes.DESC;
			else if (state === 'DESC') return ESortTypes.ASC;
			else if (state === 'ASC') return ESortTypes.NONE;
			else return ESortTypes.DESC;
		});
	};

	const handleSort = () => {
		if (!metricsWithoutSortBy.includes(metricName)) {
			requestDataForNextSorting();
			rotateSorting();
		}
	};

	if (product === 'plm') metricsWithoutSortBy.push('name')

	const getTextColoredIconTooltip = () => {
		if (IndicatorsGroups[indicator] && StagesGroups[stage]) {
			const coloredIcon =
				dataGroupSelected === 'stages' && !openColumnSelectorModal
					? IndicatorsGroups[indicator].coloredIcon
					: StagesGroups[stage].coloredIcon;
			const iconId = coloredIcon?.slice(coloredIcon.indexOf('id') + 3);
			const text = t(`${iconId}-popOver`);
			return text;
		}
	};

	const textColoredIconTooltip = getTextColoredIconTooltip();

	const textMetricUnitTooltip = () => (
		<MetricUnit
			indicator={indicator}
			metricUnit={metricUnit}
			dataType={dataType}
			styles={'font-sfpro-regular text-dark-60 font-12px'}
		/>
	);

	const dataTypeTooltipIfOverflowed = ['u', 'p'];

	return (
		<th
			key={textSubTitle}
			className={`text-overflow-ellipsis overflow-hidden align-top pt-0 pr-3 pb-1 pl-3`}
			style={{
				minWidth: textSubTitle === 'folder_url' ? '50px' : '135px',
				maxWidth: growColumn.includes(textSubTitle ?? '') ? '186px' : textSubTitle === 'folder_url' ? '50px' : '135px',
				height: '35px',
				width: textSubTitle === "completionTraceability" ? '100%' : ''
			}}
		>
			{textSubTitle && (
				<>
					<div
						className={`sort-div d-flex align-items-center
                  ${
						!metricsWithoutSortBy.includes(metricName) &&
						'cursor-pointer'
					}`}
						onClick={handleSort}
						style={{ width: growColumnTextByName.includes(textSubTitle) ? '200px' : textSubTitle === 'folder_url' ? '50px' : '125px' }}
					>
						<span
							id={textColumnId}
							onMouseEnter={() => setSubTitleTooltipOpen(true)}
							onMouseLeave={() => setSubTitleTooltipOpen(false)}
							className="font-sfpro-regular text-dark-60 font-12px text-overflow-ellipsis overflow-hidden"
						>
							{(validateReferenceType(projectSelected) && textSubTitle === 'articleInfo') ? t("material-list") : t(textSubTitle)}
						</span>
						{!metricsWithoutSortBy.includes(metricName) && (
							<SortArrows
								metricName={metricName}
								columnId={columnId}
							/>
						)}
					</div>
					{!metricsWithoutTooltip.includes(textSubTitle) && (
						<SimpleTooltip
							text={t(`${textSubTitle}-description`)}
							tooltipId={textColumnId}
							tooltipOpen={subTitleTooltipOpen}
							styles={{
								maxWidth: '250px',
								width: 'auto',
								textAlign: 'start',
							}}
						/>
					)}
				</>
			)}

			{indicator && metricUnit && dataType && (
				<div className="d-flex align-items-center">
					<span
						id={colorIconColumnId}
						className="cursor-pointer d-flex align-items-center"
						onMouseEnter={() => setColorIconTooltipOpen(true)}
						onMouseLeave={() => setColorIconTooltipOpen(false)}
					>
						<ColorIcon
							coloredIcon={
								dataGroupSelected === 'stages'
									? IndicatorsGroups[indicator].coloredIcon
									: StagesGroups[stage].coloredIcon
							}
						/>
					</span>

					<span
						className={`icon-icon-${dataType} text-dark-60 cursor-pointer`}
						style={{ marginRight: '1.5px', marginLeft: '1.5px' }}
						id={dataTypeColumnId}
						onMouseEnter={() => setFunctionalTooltipOpen(true)}
						onMouseLeave={() => setFunctionalTooltipOpen(false)}
					></span>

					<span
						className="font-sfpro-regular text-dark-60 font-12px"
						style={{ marginRight: '1.5px', marginLeft: '1.5px' }}
					>
						|
					</span>

					<div
						className="sort-div d-flex align-items-center cursor-pointer text-overflow-ellipsis overflow-hidden"
						onClick={handleSort}
					>
						<div
							ref={ref}
							id={metricUnitColumnId}
							className="position-relative text-overflow-ellipsis overflow-hidden text-dark-60"
							onMouseEnter={() => setMetricUnitTooltipOpen(true)}
							onMouseLeave={() => setMetricUnitTooltipOpen(false)}
						>
							<MetricUnit
								indicator={indicator}
								metricUnit={metricUnit}
								dataType={dataType}
								styles={
									'pr-2 font-sfpro-regular text-dark-60 font-12px text-overflow-ellipsis overflow-hidden'
								}
							/>
						</div>
						{!metricsWithoutSortBy.includes(metricName) && (
							<SortArrows
								metricName={metricName}
								columnId={columnId}
							/>
						)}
					</div>

					{/* Tooltips */}

					<FunctionalUnitTooltips
						dataType={dataType}
						tooltipId={dataTypeColumnId}
						tooltipOpen={functionalTooltipOpen}
					/>

					<SimpleTooltip
						text={textColoredIconTooltip}
						tooltipId={colorIconColumnId}
						tooltipOpen={colorIconTooltipOpen}
					/>

					{isTextOverflowed &&
						dataTypeTooltipIfOverflowed.includes(dataType) && (
							<SimpleTooltip
								JSXElement={textMetricUnitTooltip}
								tooltipId={metricUnitColumnId}
								tooltipOpen={metricUnitTooltipOpen}
							/>
						)}
					{!dataTypeTooltipIfOverflowed.includes(dataType) &&
						dataType !== 'r' && (
							<SimpleTooltip
								text={
									dataType === 'bar'
										? t(`${dataType}-description`)
										: t(`${product}-${metricUnit}-description`)
								}
								tooltipId={metricUnitColumnId}
								tooltipOpen={metricUnitTooltipOpen}
								styles={{
									maxWidth: '250px',
									textAlign: 'start',
								}}
							/>
						)}
				</div>
			)}
		</th>
	);
}

export default SubheaderColumnArrows;
function checkIfTextOverflow(ref: React.MutableRefObject<any>) {
	throw new Error('Function not implemented.');
}
